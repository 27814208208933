<template>
  <div>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="12">
          <b-button
            variant="primary"
            class="d-flex align-items-center justify-content-center"
            @click="downloadBrowser()"
          >
            <feather-icon icon="UserPlusIcon" size="16" class="mr-50" />
            <span class="text-nowrap">{{ $t("Exportar") }}</span>
          </b-button>

          <b-button v-b-modal.modal-hierachy v-if="width<768">
            <feather-icon icon="GitPullRequestIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col md="4">
          <b-form-group label="Brand">
            <v-select
              :value="$store.id"
              v-model="filterSelect.selectBrand"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="brandsOptions"
              label="brandName"
              @input="inputSelect"
              placeholder="Select brand"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'd-m-Y H:i',
                enableTime: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              @input="inputSelect"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'd-m-Y H:i',
                enableTime: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col v-for="(item, index) in cardCabezera" :key="index" lg="3" sm="6">
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>

          <!-- table -->
          <b-table
            :items="dataItems"
            :fields="fields"
            hover
            small
            caption-top
            responsive
          >
            <!-- Formato con el componente de hacer el formateo de las  cifras -->
            <template #cell(debit)="data">
              {{ data.item.debit | currency({symbol: ""}) }}
            </template>
            <template #cell(credit)="data">
              {{ data.item.credit | currency({symbol: ""}) }}
            </template>
            <template #cell(profit)="data">
              {{ data.item.profit | currency({symbol: ""}) }}
            </template>
          </b-table>
          <!--<b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="limit"
            :simple="true"
            :hide-goto-end-buttons="true"
          ></b-pagination>-->
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers @selectUser="showData" ref="HerarchiComponent" />
      </b-modal>
      <b-col class="p-0" md="3" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="showData" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {VueGoodTable} from "vue-good-table";
import store from "@/store";
import axios from "@axios";
// import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {formatDate} from "@core/utils/filter";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import whitelabelStoreModule from "../../whitelabel/whitelabelStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [
        {label: this.$t('game'), key: "gameName"},
        {label: this.$t('bet'), key: "debit"},
        {label: this.$t('won'), key: "credit"},
        {label: this.$t('profit'), key: "profit"},
      ],
      showFilter: false,
      loadingData: false,
      dataItems: [],
      dataIdUser: [],
      pages: [20, 40, 60],
      /*fields: [
        { label: "Juego", field: "nameGame" },
        { label: "Cantidad de jugadas", field: "spins" },
      ],*/
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      brandsOptions: [],
      customerOptions: [],
      currencyssOptions: [],
      filterSelect: {
        selectBrand: "",
        dateFrom: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      width: 0,
    };
  },
  computed: {
    /* productsOptions() {
      return this.$store.getters["user/productsUserOptions"];
    }, */
    cardCabezera() {
      return [
        {
          statistic: 1.866,
          color: "success",
          icon: "PlayCircleIcon",
          title: this.$t('total_spins'),
        },
        {
          statistic: "48.323,50 AR$",
          color: "danger",
          icon: "ActivityIcon",
          title: this.$t('total_sales'),
        },
        {
          statistic: "41.935,50 AR$",
          color: "success",
          icon: "TrophyIcon",
          title: this.$t('total_wins'),
        },
        {
          statistic: "6.388,00 AR$",
          color: "danger",
          icon: "DollarSignIcon",
          title: this.$t('total_profit'),
        },
      ];
    },
  },
  watch: {
    showFilter(val) {
      if (val) {
        this.getCustomer();
        this.getProducts();
        this.getCurrencys();
      }
    },
  },
  // add filters
  filters: {
    formatDate,
  },

  methods: {
    downloadBrowser() {
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const downloadFile = VUE_APP_URL + "/media/exports/excel/Games.xlsx";
      window.open(downloadFile, "_self");
    },
    async downloadFile() {
      const VUE_APP_URL = process.env.VUE_APP_URL;
      let userArray = await this.selectUserHierarchy(this.dataIdUser);
      const params = {
        model: "games",
        userArray: userArray.data,
        brandToSearch: this.filterSelect.selectBrand,
        dateStart: this.filterSelect.dateFrom,
        dateEnd: this.filterSelect.dateTo,
        exportExcel: false,
      };
      return axios.post(`${VUE_APP_URL}/exportData`, params);
    },
    async showData({id}) {
      //console.log("AQUI PUES DATA: ",userArray)
      this.dataIdUser = id;
      let userArray = await this.selectUserHierarchy(id);
      console.log("FUNCIONA PUES DATA: ", userArray.data);
      if (this.filterSelect.selectBrand == "") {
        return false;
      }
      try {
        this.loadingData = true;
        const APP_GATEWAY = process.env.VUE_APP_GATEWAY;
        //const dateFrom = this.dateFrom
        //const dateTo = this.dateTo
        const params = {
          userArray: userArray.data,
          brandToSearch: this.filterSelect.selectBrand,
          dateStart: this.filterSelect.dateFrom,
          dateEnd: this.filterSelect.dateTo,
        };
        const result = axios
            .post(`${APP_GATEWAY}/transaction/brands/filterByGame`, params, {
              headers: {uuid: process.env.VUE_APP_GATEWAY_TOKEN},
            })
            .then((r) => (this.dataItems = r.data))
            .catch((error) => console.log(error.response));
        return result;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
        this.downloadFile();
      }
    },
    async selectUserHierarchy(id) {
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency;
      const whitelabel =
          this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const params = {
        id: id,
        currency: currency,
        whitelabel: whitelabel,
      };
      let result = await axios
          .post(`${VUE_APP_URL}/treeUsers`, params)
          .then((r) => (r.data == "" ? 0 : r.data))
          .catch((error) => console.log(error.response));
      return result;
    },
    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.showData();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.showData();
    },
    inputSelect() {
      let id = this.dataIdUser;
      //const id = await this.selectUserHierarchy({ id })
      this.showData({id});
    },
    async getCustomer() {
      //   const res = await this.$store.dispatch("reports/getCustomer");
      //   this.customerOptions = res.data;
    },
    async getProducts() {
      //   const res = await this.$store.dispatch("reports/getProducts");
      //   this.productsOptions = res.data;
    },
    async getCurrencys() {
      //   const res = await this.$store.dispatch("reports/getCurrencys");
      //   this.currencyssOptions = res.data.currencys;
    },
    async getBrands() {
      const brandsGet = await store.dispatch("app-whitelabel/fetchBrands");

      let data = brandsGet.data.brands;
      for (let i = 0; data.length > i; i++) {
        this.brandsOptions.push(data[i]._id);
      }
    },
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  mounted() {
    // Register module
    const MODULE_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME))
      store.registerModule(MODULE_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    // if (!store.hasModule("reports"))
    //   store.registerModule("reports", reportsStoreModule);
    //this.showData();
    this.getProducts();
    this.getCurrencys();
    this.getBrands();
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
